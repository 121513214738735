<template>
  <div class="container-fluid">
    <div class="my-4 align-items-center d-flex">
      <h3 class="flex-grow-1">Territory Management</h3>
      <b-button variant="success" size="md" @click="addNewTerritory()"
        ><i class="fas fa-circle-plus ml-0 mr-2"></i>Add Territory</b-button
      >
    </div>

    <b-row class="my-3">
      <b-colxx cols="12">
        <b-table
          id="territory-table"
          ref="__territoryTable"
          responsive
          head-variant="dark"
          :items="territoryList"
          :fields="territory_list_fields"
          :current-page="current_page"
          :busy="saveInProgress"
          per-page="0"
        >
          <template #cell(actions)="row">
            <i
              class="fas fa-eye text-default icon-pointer"
              @click="goTerritoryDetail(row.item, row.index)"
            />
          </template>
          <template #cell(inactive_yn)="row">
            <span
              v-if="row.item.inactive_yn === true"
              class="ml-1"
              :style="{ display: 'inline-block', color: 'red' }"
              ><i class="fas fa-do-not-enter"></i
            ></span>
          </template>
        </b-table>
        <b-pagination
          v-model="current_page"
          :total-rows="total_rows"
          :per-page="per_page"
          :number-of-pages="pageTotal"
          size="sm"
          class="my-0"
          aria-controls="territory-table"
          @input="getTerritoryList(current_page)"
        ></b-pagination>
      </b-colxx>
    </b-row>

    <!--  -->
    <!-- Territory Detail Modal -->
    <!--  -->
    <b-modal
      id="territory-detail-modal"
      v-model="showTerritoryDetailModal"
      :title="territoryDetailModalTitle"
      size="lg"
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-bg-variant="default"
      header-text-variant="white"
    >
      <b-alert
        v-model="showTerritorySaveAlert"
        :variant="territorySaveAlertVariant"
        >{{ territorySaveAlertMessage }}</b-alert
      >
      <b-form-row class="align-items-center">
        <b-colxx cols="9">
          <m-text-input
            id="territory-name"
            label="Territory Name"
            :rules="{ required: true, max: 200 }"
            :value="editTerritory.territory_name"
            :alternative="false"
            :group="false"
            name="Territory Name"
            @update-value="
              (input) => {
                editTerritory.territory_name = input;
              }
            "
          />
        </b-colxx>
        <b-colxx cols="3">
          <b-form-group
            id="territory-inactive-fieldset"
            label="Inactive"
            label-for="territory-inactive-yn"
            class="field-label-form-group mt-2"
          >
            <b-form-checkbox
              id="territory-inactive-yn"
              :disabled="inactiveCheckboxDisabled"
              v-model="editTerritory.inactive_yn"
            ></b-form-checkbox>
          </b-form-group>
        </b-colxx>
        <b-colxx md="6" sm="12"
          ><b-form-group
            id="irep-fieldset"
            label="Select Sales Rep"
            label-for="irep-select"
            class="field-label-form-group mt-4"
          >
            <b-form-select
              id="irep-select"
              :options="filterSalesRepListActive"
              v-model="editTerritory.sales_rep_id"
              value-field="user_id"
              text-field="full_name"
            ></b-form-select>
          </b-form-group>
        </b-colxx>
        <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="from-date"
              label="Effective From"
              type="date"
              :value="editTerritory.effective_from_date"
              :alternative="false"
              :group="false"
              name="From Date"
              @update-value="
                (input) => {
                  editTerritory.effective_from_date = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="3"
            sm="12">
            <m-text-input
              id="est-del-date"
              label="Effective To"
              type="date"
              :value="editTerritory.effective_to_date"
              :alternative="false"
              :group="false"
              name="Effective To Date"
              @update-value="
                (input) => {
                  editTerritory.effective_to_date = input
                }
              " />
          </b-colxx>
        <!-- <b-colxx md="6" sm="12"
          ><b-form-group
            id="rrep-type-fieldset"
            label="Retail Rep"
            label-for="rrep-select"
            class="field-label-form-group"
          >
            <b-form-select
              id="rrep-select"
              :options="filterSalesRepListActive"
              v-model="editTerritory.retail_sales_rep"
              value-field="user_id"
              text-field="full_name"
            ></b-form-select>
          </b-form-group>
        </b-colxx> -->
      </b-form-row>
      <b-row>
        <b-table id="history-table"
          responsive
          head-variant="dark"
          :items="editTerritory.territory_history"
          :fields="territory_history_fields">
          </b-table>
      </b-row>
      <template #modal-footer="{}">
        <div v-if="!saveProcessComplete">
          <b-button
            size="md"
            class="mr-2"
            variant="success"
            :disabled="saveInProgress || disableSaveButton"
            @click="saveTerritory()"
          >
            <div v-if="!saveInProgress">Save</div>
            <div v-if="saveInProgress">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Saving...</span>
            </div>
          </b-button>
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            :disabled="saveInProgress"
            @click="cancelTerritoryModal()"
          >
            Cancel
          </b-button>
        </div>
        <div v-if="saveProcessComplete">
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            @click="cancelTerritoryModal()"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import django_session from "@/api/django_session";

  const territoryObj = {
    territory_id: null,
    territory_name: "",
    sales_rep_id: null,
    sales_rep_name: "",
    effective_from_date: null,
    effective_to_date: null,
    inactive_yn: false,
    territory_history: [],
  };

  export default {
    name: "TerritoryHome",
    components: {
      // McpInput,
    },

    data() {
      return {
        territoryList: [],
        territory_list_fields: [
          {
            label: "",
            key: "actions",
          },
          {
            label: "Name",
            key: "territory_name",
          },
          {
            label: "Sales Rep",
            key: "sales_rep_name",
          },
          // {
          //   label: "Retail Rep",
          //   key: "retail_rep_name",
          // },
          {
            label: "Inactive",
            key: "inactive_yn",
          },
          // {
          //   label: "Company Count",
          //   key: "company_count",
          // },
        ],
        territory_history_fields: [
          {
            label: "Sales Rep",
            key: "sales_rep_name"
          },
          {
            label: "Effective From Date",
            key: "effective_from_date"
          },
          {
            label: "Effective To Date",
            key: "effective_to_date"
          },
          {
            label: "Changed By",
            key: "created_by_name"
          },
        ],
        current_page: 1,
        per_page: 10,
        total_rows: null,
        prev_page_link: "",
        next_page_link: "",

        filterTerritoryName: "",
        hideInactiveYN: true,
        createNewTerritoryYN: false,
        showTerritoryDetailModal: false,
        editTerritory: {},
        editTerritoryIndex: null,

        salesRepList: [],

        showTerritorySaveAlert: false,
        territorySaveAlertVariant: "",
        territorySaveAlertMessage: "",

        saveTerritoryComplete: false,
        saveInProgress: false,
        saveProcessComplete: false,
      };
    },

    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters("common", ["currentPage"]),

      pageTotal() {
        var page_total = 1;
        page_total = Math.ceil(this.total_rows / this.per_page);
        return page_total;
      },

      territoryDetailModalTitle() {
        if (this.createNewTerritoryYN === true) {
          return "Create Territory";
        } else {
          return "Edit Details";
        }
      },

      filterSalesRepListActive() {
        let list = this.salesRepList;
        let filtered_list = list.filter((x) => x.is_active_yn === true);
        return filtered_list;
      },

      inactiveCheckboxDisabled() {
        if (this.editTerritory.company_count !== 0) {
          return true;
        } else {
          return false;
        }
      },

      disableSaveButton() {
        if (this.editTerritory.territory_name === "" || this.editTerritory.sales_rep_id === null || this.editTerritory.effective_from_date === null) {
          return true
        }
        return false
      }
    },

    created() {},

    async mounted() {
      document.title = "Territory Master List";
      await django_session.get("erp/user/list-sales-rep/", {
        params: {
          page_size: 0
        }
      }).then((response) => {
        this.salesRepList = response.data;
      });
      this.getTerritoryList();
    },

    methods: {
      async getTerritoryList() {
        await django_session
          .get("erp/customer/territory/list/", {
            params: {
              page: this.current_page,
              // keyword: this.searchCustomerName
            },
          })
          .then((response) => {
            this.total_rows = response.data.count;
            this.prev_page_link = response.data.previous;
            this.next_page_link = response.data.next;
            this.territoryList = response.data.results;
          })
          .catch((error) => {
            console.log(error);
          });
      },

      async goTerritoryDetail(item, index) {
        await django_session.get("erp/customer/territory/" + item.territory_id + "/").then((response) => {
          this.editTerritory = response.data
          if (this.editTerritory.effective_from_date === null) {
            this.editTerritory.effective_from_date = "1900-01-01"
          }
        })
        // this.editTerritory = item;
        this.editTerritoryIndex = index;
        this.createNewTerritoryYN = false;
        this.showTerritoryDetailModal = true;
      },

      cancelTerritoryModal() {
        this.showTerritoryDetailModal = false;
        this.editTerritory = {};
        this.editTerritoryIndex = null;
        this.territorySaveAlertMessage = "";
        this.territorySaveAlertVariant = "";
        this.showTerritorySaveAlert = false;
        this.saveInProgress = false;
        this.saveProcessComplete = false;
      },

      async addNewTerritory() {
        this.createNewTerritoryYN = true;
        this.editTerritoryIndex = null;
        this.editTerritory = JSON.parse(JSON.stringify(territoryObj));
        this.editTerritory.effective_from_date = new Date().toISOString().slice(0, 10);
        this.showTerritoryDetailModal = true;
      },

      async saveTerritory() {
        this.saveInProgress = true;
        if (this.createNewTerritoryYN === false) {
          await django_session
            .patch(
              "erp/customer/territory/" + this.editTerritory.territory_id + "/",
              {
                territory_id: this.editTerritory.territory_id,
                territory_name: this.editTerritory.territory_name,
                sales_rep_id:
                  this.editTerritory.sales_rep_id,
                effective_from_date: this.editTerritory.effective_from_date,
                effective_to_date: this.editTerritory.effective_to_date,
                inactive_yn: this.editTerritory.inactive_yn,
              }
            )
            .then((response) => {
              console.log(response.data);
              this.territoryList[this.editTerritoryIndex].sales_rep_id =
                response.data.sales_rep_id;
              this.territoryList[this.editTerritoryIndex].sales_rep_name =
                response.data.sales_rep_name;
              this.territoryList[this.editTerritoryIndex].territory_id =
                response.data.territory_id;
              this.territoryList[this.editTerritoryIndex].territory_name =
                response.data.territory_name;
              this.territoryList[this.editTerritoryIndex].inactive_yn =
                response.data.inactive_yn;

              this.territorySaveAlertMessage = "Update Successful";
              this.territorySaveAlertVariant = "success";
            })
            .catch((error) => {
              console.log(error);
              this.territorySaveAlertMessage = "Save Error";
              this.territorySaveAlertVariant = "danger";
            });
        }
        if (this.createNewTerritoryYN === true) {
          await django_session
            .post("erp/customer/territory/create/", {
              territory_name: this.editTerritory.territory_name,
              sales_rep_id: this.editTerritory.sales_rep_id,
              effective_from_date: this.editTerritory.effective_from_date,
              effective_to_date: this.editTerritory.effective_to_date,
              inactive_yn: this.editTerritory.inactive_yn,
            })
            .then((response) => {
              console.log(response.data);
              this.editTerritory = JSON.parse(JSON.stringify(response.data));
              this.territoryList.push = response.data;
              this.territorySaveAlertMessage = "New Territory Created";
              this.territorySaveAlertVariant = "success";
            })
            .catch((error) => {
              console.log(error);
              this.territorySaveAlertMessage = "Save Error";
              this.territorySaveAlertVariant = "danger";
            });
        }
        this.showTerritorySaveAlert = true;
        this.saveInProgress = false;
        this.saveProcessComplete = true;
      },
    },
  };
</script>

<style></style>
