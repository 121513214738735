<template>
  <div>
    <b-container>
      <b-row class="my-4 display-3"><b-colxx md="12" sm="12">Release Notes</b-colxx></b-row>
      <b-row class="my-1">
        <b-colxx md="12" sm="12">
          <div v-for="item in releaseNotes" :key="item.version">
            <b-row class="my-1 display-4"
              ><b-colxx md="12" sm="12">Version {{ item.version }} - {{ item.release_date }}</b-colxx></b-row
            >
            <b-row>
              <ul>
                <li v-for="(note, index) in item.notes" :key="index">{{ note }}</li>
              </ul>
            </b-row>
          </div>
        </b-colxx>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { releaseNotes } from "@/constants/release_notes";
export default {
  name: "ReleaseNotes",
  components: {},

  data() {
    return {
      releaseNotes,
    };
  },

  computed: {},

  created() {},

  mounted() {
    document.title = "Release Notes";
  },

  methods: {},
};
</script>
