<template>
  <div class="container-fluid my-4">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="card">
          <div class="card-header bg-info text-white">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 text-lg font-weight-bold">Pump Type List</div>
              <div class="flex">
                <b-button variant="success" @click="createPumpType"><i class="fas fa-circle-plus mr-2" /> New</b-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <b-table
              id="pump-type-table"
              ref="__pumpTypeTable"
              responsive
              head-variant="dark"
              :items="pumpTypes"
              :fields="pump_type_list_fields"
              per-page="0"
              :busy="isSaving"
            >
              <template #cell(action)="row">
                <i
                  class="fas fa-pencil text-default icon-pointer"
                  @click="editType(row.item, row.index)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="card">
          <div class="card-header bg-primary text-white">{{ pumpTypeCardTitle }}</div>
          <div class="card-body">
            <b-row class="my-1">
              <b-alert v-model="showAlert" :variant="alertVariant" class="w-100" dismissible>{{
                alertMessage
              }}</b-alert>
            </b-row>
            <div class="row">
              <b-colxx md="12" sm="12"
                ><m-text-input
                  id="name"
                  label="Pump Type Name"
                  :rules="{ required: true, max: 200 }"
                  :value="editPumpType.name"
                  :alternative="false"
                  :group="false"
                  name="Pump Type Name"
                  @update-value="
                    (input) => {
                      editPumpType.name = input;
                    }
                  "
              /></b-colxx>
              <b-colxx md="12" sm="12"
                ><m-text-input
                  id="name-fr"
                  label="Pump Type Name (FR)"
                  :rules="{ required: false, max: 200 }"
                  :value="editPumpType.name_fr"
                  :alternative="false"
                  :group="false"
                  name="Pump Type Name (FR)"
                  @update-value="
                    (input) => {
                      editPumpType.name_fr = input;
                    }
                  "
              /></b-colxx>
              <b-colxx md="12" sm="12"
                ><m-text-input
                  id="warranty"
                  label="Warranty (Yrs)"
                  :rules="{ required: false }"
                  :value="editPumpType.warranty_years"
                  :alternative="false"
                  :group="false"
                  name="Warranty (Yrs)"
                  type="number"
                  @update-value="
                    (input) => {
                      editPumpType.warranty_years = input;
                    }
                  "
              /></b-colxx>
            </div>
          </div>
          <div class="card-footer">
            <b-button variant="primary" :disabled="disableSaveButton" @click="savePumpType()">Save</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import django_session from "@/api/django_session";

import { pumpTypeObj } from "@/constants/objects/pump"

export default {
  name: "PumpTypes",
  components: {},

  data() {
    return {
      // pumpTypeList: [],
      pump_type_list_fields: [
        {
          key: "action",
          label: "",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "warranty_years",
          label: "Warranty",
        },
      ],

      editPumpType: {},

      editPumpTypeIndex: null,

      pumpTypeCardTitle: "Create New",
      editPumpTypeYN: false,

      showAlert: false,
      alertMessage: "",
      alertVariant: "",

      isSaving: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("pump", ["pumpTypes"]),

    disableSaveButton() {
      if (this.editPumpType.name !== "") {
        return false
      }
      return true
    },
  },

  created() {},

  mounted() {
    document.title = "Manage Pump Types";
    // django_session
    //   .get("erp/pump/type/list/", {
    //     params: {
    //       page_size: 0
    //     }
    //   })
    //   .then((response) => {
    //     this.pumpTypeList = response.data;
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
    this.editPumpType = JSON.parse(JSON.stringify(pumpTypeObj))
  },

  methods: {
    createPumpType() {
      this.editPumpTypeIndex = null;
      this.pumptTypeCardTitle = "Create New";
      this.editPumpTypeYN = false;
      this.editPumpType = JSON.parse(JSON.stringify(pumpTypeObj))
      this.showAlert = false;
      this.alertMessage = "";
      this.alertVariant = "";
    },

    editType(item, index) {
      this.editPumpTypeYN = true;
      this.editPumpType = JSON.parse(JSON.stringify(item));
      this.pumptTypeCardTitle = "Edit " + item.name;
      this.editPumpTypeIndex = index;
      this.showAlert = false;
      this.alertMessage = "";
      this.alertVariant = "";
    },

    async savePumpType() {
      // this.isSaving = true
      if (this.editPumpTypeYN === false) {
        await django_session
          .post("erp/pump/type/create/", {
            name: this.editPumpType.name,
            name_fr: this.editPumpType.name_fr,
            warranty_years: this.editPumpType.warranty_years,
            item_id: this.editPumpType.item_id,
            selected: this.editPumpType.selected,
            created_by: this.user.user_id,
            modified_by: this.user.user_id
          })
          .then((response) => {
            // this.pumpTypeList.push(response.data);
            this.$store.dispatch("pump/addPumpType", response.data)
            this.alertMessage = "Pump Type Created";
            this.alertVariant = "success";
            this.showAlert = true;
          })
          .then(() => {
            this.editPumpTypeYN = false;
            this.editPumpType = JSON.parse(JSON.stringify(pumpTypeObj))
            this.editPumpTypeIndex = null;
          })
          .catch((error) => {
            this.alertMessage = "Error in creating pump type.";
            this.alertVariant = "warning";
            this.showAlert = true;
          });
      }
      if (this.editPumpTypeYN === true) {
        await django_session
          .patch("erp/pump/type/" + this.editPumpType.pump_type_id + "/", {
            name: this.editPumpType.name,
            name_fr: this.editPumpType.name_fr,
            warranty_years: this.editPumpType.warranty_years,
            item_id: this.editPumpType.item_id,
            selected: this.editPumpType.selected,
            modified_by: this.user.user_id
          })
          .then((response) => {
            // this.pumpTypeList[this.editPumpTypeIndex] = response.data;
            let payload = {
              index: this.editPumpTypeIndex,
              data: response.data
            }
            this.$store.dispatch("pump/editPumpType", payload)
            this.alertMessage = "Pump Type Updated";
            this.alertVariant = "success";
            this.showAlert = true;
          })
          .then(() => {
            this.editPumpTypeYN = false;
            this.editPumpType = JSON.parse(JSON.stringify(pumpTypeObj))
            this.editPumpTypeIndex = null;
          })
          .catch((error) => {
            this.alertMessage = "Error in updating pump type.";
            this.alertVariant = "warning";
            this.showAlert = true;
          });
      }
      // this.isSaving = false
      this.$refs.__pumpTypeTable.refresh()
      this.$root.$emit('bv::refresh::table', 'pump-type-table')
    },
  },
};
</script>

<style scoped></style>
