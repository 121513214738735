<template>
  <div class="container-fluid my-4">
    <div v-if="!showLoader" class="row">
      <div class="col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header bg-info text-white">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 text-lg font-weight-bold">{{ cardTitle }}</div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="!showDetail">
              <b-table
                id="price-list-table"
                ref="__priceListTable"
                responsive
                head-variant="dark"
                :items="priceLists"
                :fields="price_list_fields"
                :current-page="current_page"
                per-page="0"
              >
                <template #cell(action)="row">
                  <i class="fas fa-eye text-primary icon-pointer" @click="getPriceListDetail(row.item)" />
                </template>
                <template #cell(bactive)="row">
                  <i
                    class="fas"
                    :class="row.item.bactive ? 'fa-circle-check text-success' : 'fa-do-not-enter text-warning'"
                  />
                </template>
                <template #cell(dtasdate)="row">
                  {{ new Date(row.item.dtasdate).toLocaleDateString() }}
                </template>
              </b-table>
            </div>

            <!-- Price List Detail -->
            <div v-if="showDetail">
              <b-row class="my-3 align-items-center">
                <b-colxx md="9" sm="9">
                  <!-- <b-button @click="showDetail = false">Hide</b-button> -->
                  <b-form-group class="mb-0">
                    <b-input-group class="">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                      </div>
                      <b-form-input v-model.trim="filterItemName" placeholder="Search for an item by name" type="text">
                      </b-form-input>
                      <div class="input-group-append">
                        <span class="input-group-text"
                          ><i class="fas fa-circle-xmark icon-pointer text-danger" @click="filterItemName = ''"></i
                        ></span>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-colxx>
                <b-colxx md="3" sm="3">
                  <b-button variant="info" @click="showDetail = false">Back</b-button>
                </b-colxx>
              </b-row>

              <b-table
                id="price-list-detail-table"
                ref="__priceListDetailTable"
                responsive
                head-variant="dark"
                :items="priceListDetail.price_list_items"
                :fields="price_list_detail_fields"
                :current-page="current_page"
                :filter-included-fields="filterOn"
                :filter="filterItemName"
                per-page="0"
              >
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import django_session from "@/api/django_session";

export default {
  name: "PriceList",
  components: {},

  data() {
    return {
      priceLists: [],
      price_list_fields: [
        {
          key: "action",
          label: "",
        },
        {
          key: "sdesc",
          label: "Name",
        },
        {
          key: "dtasdate",
          label: "Created",
        },
        {
          key: "customer_count",
          label: "# Customers",
        },
      ],
      price_list_detail_fields: [
        {
          key: "item_name",
          label: "Name",
        },
        {
          key: "code",
          label: "SKU",
        },
        {
          key: "formatted_price",
          label: "Price",
        },
      ],

      filterItemName: "",
      filterOn: ["item_name"],
      current_page: 1,

      showDetail: false,
      priceListDetail: {},
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["showLoader"]),

    cardTitle() {
      if (this.showDetail === true) {
        return this.priceListDetail.sdesc;
      }
      return "Price Lists";
    },
  },

  created() {},

  async mounted() {
    this.$store.dispatch("common/showLoader", true);
    document.title = "Price Lists";
    django_session
      .get("sage/price-list/", {
        params: {
          page_size: 0,
          page: this.current_page,
        },
      })
      .then((response) => {
        this.priceLists = response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
    this.$store.dispatch("common/showLoader", false);
  },

  methods: {
    async getPriceListDetail(item) {
      this.$store.dispatch("common/showLoader", true);
      await django_session.get("sage/price-list/" + item.lid + "/").then((response) => {
        this.priceListDetail = response.data;
        this.showDetail = true;
      });
      this.$store.dispatch("common/showLoader", false);
    },
  },
};
</script>

<style scoped></style>
