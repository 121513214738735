<template>
	<div class="container-fluid my-4">
		<div class="row">
			<div class="col-md-6 col-sm-12">
				<div class="card">
					<div class="card-header bg-info text-white">
						<div class="d-flex align-items-center">
							<div class="flex-grow-1 text-lg font-weight-bold">
								Category List
							</div>
							<div class="flex"></div>
						</div>
					</div>
					<div class="card-body">
						<b-table
							id="customer-table"
							ref="__customerTable"
							responsive
							head-variant="dark"
							:items="categoryList"
							:fields="category_list_fields"
							:current-page="current_page"
							per-page="0"
						>
							<template #cell(action)="row">
								<i
									class="fas fa-pencil text-default icon-pointer"
									@click="editCategory(row.item, row.index)"
								/>
							</template>
							<template #cell(category_colour)="row">
								<b-form-input
									id="show-category-color"
									v-model="row.item.category_colour"
									disabled
									type="color"
								></b-form-input>
							</template>
						</b-table>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-sm-12">
				<div class="card">
					<div class="card-header bg-primary text-white">
						<span class="pl-1 pb-1">{{ categoryCardTitle }}</span>
						<i
							@click="createCategory"
							class="fas fa-circle-plus mr-1 float-right icon-pointer"
						/>
					</div>

					<div class="card-body">
						<b-row class="my-1">
							<b-alert
								v-model="showAlert"
								:variant="alertVariant"
								class="w-100"
								dismissible
								>{{ alertMessage }}</b-alert
							>
						</b-row>
						<div class="row">
							<b-colxx
								md="8"
								sm="12"
								><m-text-input
									id="category-name"
									label="Category Name"
									:rules="{ required: true, max: 100 }"
									:value="customer_category.customer_category_name"
									:alternative="false"
									:group="false"
									name="Category Name"
									@update-value="
										(input) => {
											customer_category.customer_category_name = input;
										}
									"
							/></b-colxx>
							<b-colxx
								md="4"
								sm="12"
								><b-form-group
									id="category-color-fieldset"
									label="Category Color"
									label-for="category-color"
									class="field-label-form-group mt-2"
								>
									<b-form-input
										id="category-color"
										v-model="customer_category.category_colour"
										type="color"
									></b-form-input> </b-form-group
							></b-colxx>
						</div>
					</div>
					<div class="card-footer">
						<b-button
							variant="primary"
							:disabled="disableSaveButton"
							@click="saveCategory()"
							>Save</b-button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import django_session from "@/api/django_session";

	const categoryObj = {
		category_id: null,
		customer_category_name: "",
		category_colour: "#000000",
		updated_by: null,
	};

	export default {
		name: "CustomerTypes",
		components: {},

		data() {
			return {
				categoryList: [],
				category_list_fields: [
					{
						key: "action",
						label: "",
					},
					{
						key: "customer_category_name",
						label: "Category Name",
					},
					{
						key: "category_colour",
						label: "Colour",
					},
				],
				customer_category: {
					category_id: null,
					customer_category_name: "",
					category_colour: "#000000",
					updated_by: null,
				},
				categoryCardTitle: "Category Detail",
				editCategoryYN: null,
				editCategoryIndex: null,
				current_page: 1,

				showAlert: false,
				alertMessage: "",
				alertVariant: "",
			};
		},

		computed: {
			...mapGetters("auth", ["user"]),

			disableSaveButton() {
				if (this.customer_category.customer_category_name !== "") {
					return false;
				} else {
					return true;
				}
			},
		},

		created() {},

		async mounted() {
			document.title = "Manage Customer Categories";
			await django_session
				.get("erp/customer/category/list/", {
					params: {
						page_size: 0,
					},
				})
				.then((response) => {
					this.categoryList = response.data;
				})
				.catch((error) => {
					console.log(error.response);
				});

			this.createCategory();
		},

		methods: {
			createCategory() {
				var randomColor = "#000000".replace(/0/g, function () {
					return (~~(Math.random() * 16)).toString(16);
				});
				this.editCategoryIndex = null;
				this.categoryCardTitle = "Create New";
				this.editCategoryYN = false;
				this.customer_category = {
					customer_category_name: "",
					category_colour: randomColor,
					category_id: null,
				};
			},

			editCategory(item, index) {
				this.editCategoryYN = true;
				this.customer_category = JSON.parse(JSON.stringify(item));
				this.editCategoryIndex = index;
			},

			async saveCategory() {
				if (this.editCategoryYN === false) {
					await django_session
						.post("erp/customer/category/create/", {
							customer_category_name:
								this.customer_category.customer_category_name,
							category_colour: this.customer_category.category_colour,
						})
						.then((response) => {
							this.categoryList.push(response.data);
							this.alertMessage = "Category created";
							this.alertVariant = "success";
							this.showAlert = true;
						})
						.then(() => {
							this.editCategoryYN = false;
							this.customer_category = {
								category_id: null,
								customer_category_name: "",
								category_colour: "#000000",
								updated_by: null,
							};
							this.editCategoryIndex = null;
						})
						.catch((error) => {
							console.log(error.response);
						});
				}
				if (this.editCategoryYN === true) {
					await django_session
						.patch(
							"erp/customer/category/" +
								this.customer_category.category_id +
								"/",
							{
								category_id: this.customer_category.category_id,
								customer_category_name:
									this.customer_category.customer_category_name,
								category_colour: this.customer_category.category_colour,
							}
						)
						.then((response) => {
							this.categoryList[this.editCategoryIndex].category_colour = response.data.category_colour
							this.categoryList[this.editCategoryIndex].customer_category_name = response.data.customer_category_name
							this.categoryList[this.editCategoryIndex].category_id = response.data.category_id
							this.categoryList[this.editCategoryIndex].updated_by = response.data.updated_by
							this.categoryList[this.editCategoryIndex].updated_at = response.data.updated_at
							this.alertMessage = "Category updated";
							this.alertVariant = "success";
							this.showAlert = true;
						})
						.then(() => {
							var randomColor = "#000000".replace(/0/g, function () {
								return (~~(Math.random() * 16)).toString(16);
							});
							this.editCategoryYN = false;
							this.customer_category = {
								category_id: null,
								customer_category_name: "",
								category_colour: randomColor,
								updated_by: null,
							};
							this.editCategoryIndex = null;
						})
						.catch((error) => {
							console.log(error.response);
						});
				}
			},
		},
	};
</script>

<style scoped></style>
