<template>
  <div v-if="!showLoader" class="container-fluid">
    <m-page-title title="Roles & Permissions" />

    <b-row class="my-3">
      <b-colxx md="6" sm="12">
        <div class="card">
          <div class="card-header bg-primary align-items-center d-flex">
            <span class="h3 flex-grow-1 text-white">User Roles</span>
            <span class="flex-shrink"
              ><b-button variant="success" @click="createRole()"
                ><i class="fas fa-circle-plus mr-2" />New</b-button
              ></span
            >
          </div>
          <div class="card-body">
            <b-table
              id="user-role-table"
              ref="__userRoleTable"
              responsive
              head-variant="dark"
              :items="userRoleList"
              :fields="user_role_list_fields"
              per-page="0"
            >
              <template #cell(actions)="row">
                <span v-if="row.item.user_role_id === null">
                  <i
                    class="fas fa-circle-check text-success icon-pointer mr-2"
                    @click="addNewRole(row.item, row.index)"
                  />
                  <i
                    class="fas fa-circle-xmark text-danger icon-pointer"
                    @click="cancelAddNewRole(row.item, row.index)"
                  />
                </span>
                <span v-else>
                  <i
                    v-if="!addingNewRole"
                    class="fas fa-pencil text-default icon-pointer"
                    @click="selectUserRole(row.item, row.index)"
                  />
                </span>
              </template>
              <template #cell(user_role_description)="row">
                <span v-if="row.item.user_role_id === null">
                  <b-form-input
                    v-model="selectedUserRole.user_role_description"
                    size="sm"
                    class="w-100"
                  />
                </span>
                <span v-else>{{ row.item.user_role_description }}</span>
              </template>
            </b-table>
          </div>
        </div>
      </b-colxx>
      <b-colxx v-if="showPermissionList" md="6" sm="12">
        <div class="card">
          <div class="card-header bg-primary align-items-center d-flex">
            <h3 class="flex-grow-1 text-white">
              {{ selectedUserRole.user_role_description }}
            </h3>
            <div class="flex-shrink">
              <b-button
                size="md"
                variant="success"
                class="float-right"
                :disabled="saveInProgress"
                @click="saveUserRole()"
                ><i class="fas fa-floppy-disk ml-0 mr-2"></i
                >{{ saveButtonText }}</b-button
              >
            </div>
          </div>

          <div class="card-body">
            <div v-if="saveInProgress" class="text-center">
              <m-loader class="align-middle"></m-loader>
            </div>
            <div v-if="!saveInProgress">
              <b-row class="my-1">
                <b-alert
                  v-model="showSaveAlert"
                  :variant="alertVariant"
                  class="w-100"
                  dismissible
                  >{{ saveMessage }}</b-alert
                >
              </b-row>
              <!-- <b-alert show variant="warning" dismissible class="w-100">Working through glitch on checkboxes not refreshing properly after a change and save.</b-alert> -->
              <div
                v-for="(p, index) in userPermissionList"
                :key="p.user_permission_id"
              >
                <base-checkbox
                  :item="p"
                  :index="index"
                  :checked="p.checked"
                  @update-checkbox="updatePersmissionCheckbox(p, index)"
                  >{{ p.user_permission_display }}</base-checkbox
                >
              </div>
              <m-tracking-info
                :created-by-name="selectedUserRole.created_by_name"
                :created-at="selectedUserRole.created_at"
                :modified-by-name="selectedUserRole.modified_by_name"
                :modified-at="selectedUserRole.modified_at"
              />
            </div>
          </div>
        </div>
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import django_session from "@/api/django_session";
import BaseCheckbox from "@/components/input/BaseCheckbox.vue";
import MLoader from "@/components/loader/MLoader.vue";

export default {
  name: "UserRoleHome",
  components: {
    BaseCheckbox,
    MLoader,
  },

  data() {
    return {
      userRoleList: [],
      user_role_list_fields: [
        {
          label: "",
          key: "actions",
        },
        {
          label: "Name",
          key: "user_role_description",
        },
      ],

      userPermissionList: [],
      user_permission_list_fields: [
        {
          label: "",
          key: "actions",
        },
        {
          label: "Name",
          key: "user_permission_description",
        },
      ],

      selectedUserRole: {},
      selectedUserRoleIndex: null,
      editRoleYN: null,
      addingNewRole: false,
      showPermissionList: false,

      saveInProgress: false,
      showSaveAlert: false,
      alertVariant: "",
      saveMessage: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["showLoader"]),

    saveButtonText() {
      if (this.saveInProgress) {
        return "Saving...";
      } else {
        return "Save";
      }
    },
  },

  created() {},

  mounted() {
    this.$store.dispatch("common/showLoader", true);
    document.title = "Users & Permissions";
    this.getUserRoleList();
    this.getUserPermissionList();
    this.$store.dispatch("common/showLoader", false);
  },

  methods: {
    async getUserRoleList() {
      await django_session
        .get("erp/user/role/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.userRoleList = response.data;
        });
    },

    async getUserPermissionList() {
      await django_session
        .get("erp/user/permission/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.userPermissionList = response.data;
        });
    },

    createRole() {
      this.editRoleYN = false;
      this.addingNewRole = true;
      this.showPermissionList = false;
      this.selectedUserRole = {
        user_role_id: null,
        user_role_code: "",
        user_role_description: "",
        permissions: [],
      };
      this.userRoleList.push(this.selectedUserRole);
      // const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      // return new Promise((resolve, reject) => {
      //   for (var i = 0; i < checkboxes.length; i++) {
      //     checkboxes[i].checked = false;
      //     console.log(checkboxes[i].checked);
      //   }
      //   resolve();
      // });
    },

    addNewRole() {
      this.selectedUserRoleIndex = this.userRoleList.length - 1;
      this.selectedUserRole.permissions = [];
      this.selectedUserRole.user_role_code =
        this.selectedUserRole.user_role_description
          .replace(/\s+/g, "-")
          .toUpperCase();
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      return new Promise((resolve, reject) => {
        for (var i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false;
          // console.log(checkboxes[i].checked);
        }
        this.showPermissionList = true;
        resolve();
      });
    },

    cancelAddNewRole() {
      this.selectedUserRole = {};
      this.userRoleList.pop();
      this.addingNewRole = false;
      this.showPermissionList = false;
    },

    resetCheckboxes() {
      this.editRoleYN = false;
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      return new Promise((resolve, reject) => {
        for (var i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false;
          // console.log(checkboxes[i].checked);
        }
        resolve();
      });
    },

    selectUserRole(item, index) {
      this.showPermissionList = false;
      this.resetCheckboxes().then(() => {
        this.saveMessage = "";
        this.alertVariant = "";
        this.showSaveAlert = false;
        this.selectedUserRole = JSON.parse(JSON.stringify(item));
        this.selectedUserRoleIndex = index;
        for (var i = 0; i < this.userPermissionList.length; i++) {
          let has_permission = this.selectedUserRole.permissions.find(
            (perm) =>
              perm.user_permission_id ===
              this.userPermissionList[i].user_permission_id
          );
          if (has_permission) {
            Object.assign(this.userPermissionList[i], {
              checked: true,
            });
            // console.log(this.userPermissionList[i].checked);
          } else {
            Object.assign(this.userPermissionList[i], {
              checked: false,
            });
            // console.log(this.userPermissionList[i].checked);
          }
        }
        this.editRoleYN = true;
        this.showPermissionList = true;
      });
    },

    updatePersmissionCheckbox(item, index) {
      console.log(item, index);
      let findPermissionIndex = this.selectedUserRole.permissions.findIndex(
        (perm) => perm.user_permission_id === item.user_permission_id
      );
      console.log(findPermissionIndex);
      if (findPermissionIndex !== -1) {
        // console.log("Remove");
        this.selectedUserRole.permissions.splice(findPermissionIndex, 1);
      }
      if (findPermissionIndex === -1) {
        // console.log("Add");
        this.selectedUserRole.permissions.push(item);
      }
    },

    async saveUserRole() {
      this.saveInProgress = true;
      if (this.editRoleYN === true) {
        await django_session
          .patch("erp/user/role/" + this.selectedUserRole.user_role_id + "/", {
            user_role_id: this.selectedUserRole.user_role_id,
            user_role_code: this.selectedUserRole.user_role_code,
            user_role_description: this.selectedUserRole.user_role_description,
            permissions: this.selectedUserRole.permissions,
          })
          .then((response) => {
            this.userPermissionList.forEach((item) => {
              item.checked = false;
              response.data.permissions.forEach((perm) => {
                if (perm.user_permission_id === item.user_permission_id) {
                  item.checked = true;
                  console.log(true);
                }
              });
            });

            this.saveInProgress = false;
            this.saveMessage = "User role updated successfully.";
            this.alertVariant = "success";
            this.showSaveAlert = true;
            // this.editRoleYN = null;
            console.log(response.data);
          })
          .catch((error) => {
            this.saveInProgress = false;
            this.saveMessage = "There was an error in the save process.";
            this.alertVariant = "danger";
            this.showSaveAlert = true;
            // console.log(error);
          });
      }

      if (this.editRoleYN === false) {
        await django_session
          .post("erp/user/role/create/", {
            user_role_code: this.selectedUserRole.user_role_code,
            user_role_description: this.selectedUserRole.user_role_description,
            permissions: this.selectedUserRole.permissions,
          })
          .then((response) => {
            this.userRoleList[this.selectedUserRoleIndex] = response.data;
            this.selectedUserRole = response.data;
            this.saveInProgress = false;
            this.saveMessage = "User role created successfully.";
            this.alertVariant = "success";
            this.showSaveAlert = true;
            // this.editRoleYN = null;
            // console.log(response.data);
          })
          .catch((error) => {
            this.saveInProgress = false;
            this.saveMessage = "There was an error in the save process.";
            this.alertVariant = "danger";
            this.showSaveAlert = true;
            console.log(error.response.data);
          });
      }
      this.$store.dispatch("common/showLoader", true);
      // await this.getUserRoleList();
      // this.selectedUserRoleIndex = null;
      // this.selectedUserRole = {};
      // this.editRoleYN = null;
      this.addingNewRole = false;
      // this.showPermissionList = false;
      this.$store.dispatch("common/showLoader", false);
    },
  },
};
</script>

<style></style>
