<template>
  <div v-if="!showLoader">
    <m-page-title title="User Management" />
    <b-row class="my-3">
      <b-colxx cols="12">
        <div class="card">
          <div class="card-header bg-primary align-items-center d-flex">
            <h3 class="flex-grow-1 text-white">Users</h3>
            <div class="flex-shrink">
              <b-button
                size="md"
                variant="info"
                class="float-right"
                @click="openAzureUsersModal()"
                ><i class="fas fa-plus ml-0 mr-2"></i>Add Users</b-button
              >
            </div>
          </div>
          <div class="card-body">
            <b-table
              id="user-table"
              ref="__userTable"
              responsive
              head-variant="dark"
              :busy="saveInProgress"
              :items="userList"
              :fields="user_list_fields"
              :current-page="current_page"
              per-page="0">
              <template #cell(actions)="row">
                <i
                  class="fas fa-pencil text-default icon-pointer"
                  @click="goUserDetail(row.item, row.index)" />
              </template>
              <template #cell(roles)="row">
                <div
                  v-for="item in row.item.user_roles"
                  :key="item.user_role_id"
                  class="badge text-white bg-info mr-1">
                  {{ item.user_role_description }}
                </div>
              </template>
              <template #cell(is_active_yn)="row">
                <i
                  :class="
                    row.item.is_active_yn ? 'fas fa-circle-check text-success' : 'fas fa-circle-xmark text-danger'
                  " />
              </template>
            </b-table>
            <b-pagination
              v-model="current_page"
              :total-rows="total_rows"
              :per-page="per_page"
              :number-of-pages="pageTotal"
              size="sm"
              class="my-0"
              aria-controls="user-table"
              @input="getUserList(current_page)"></b-pagination>
          </div>
        </div>
      </b-colxx>
    </b-row>

    <!--  -->
    <!-- Azure Users Modal -->
    <!--  -->
    <b-modal
      id="azure-user-modal"
      v-model="azureUsersModal"
      title="Select a User from Microsoft Active Directory"
      size="lg"
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      scrollable
      ok-title="Cancel"
      header-bg-variant="default"
      header-text-variant="white">
      <b-alert
        v-model="confirmAddUserAlert"
        variant="info"
        >Selecting 'Confirm' will create a user profile for <strong>{{ addUser.displayName }}</strong> in the
        application. You will need to add a role (or roles) to the user profile once it has been created.</b-alert
      >
      <b-alert
        v-model="userAddSuccess"
        variant="success"
        >The user has been added to the application. Please make sure to assign a role.</b-alert
      >
      <b-table
        id="azure-user-table"
        ref="__azureUserTable"
        responsive
        head-variant="dark"
        :items="filteredAzureUsers"
        :fields="azure_user_list_fields"
        :current-page="current_page"
        per-page="0">
        <template #cell(actions)="row">
          <i
            class="fas fa-circle-plus text-default icon-pointer"
            @click="addAzureUser(row.item, row.index)" />
        </template>
      </b-table>
      <template #modal-footer="{}">
        <b-button
          size="md"
          class="mr-2"
          variant="primary"
          @click="cancelAddAzureUser()">
          <div>Cancel</div>
        </b-button>
      </template>
    </b-modal>

    <!--  -->
    <!-- User Detail Modal -->
    <!--  -->
    <b-modal
      id="user-detail-modal"
      v-model="editUserModal"
      title="Edit User"
      size="lg"
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      header-bg-variant="default"
      header-text-variant="white">
      <b-alert
        v-model="showUserSaveAlert"
        :variant="userSaveAlertVariant"
        >{{ userSaveAlertMessage }}</b-alert
      >
      <b-alert
        v-model="showInactiveWarning"
        variant="danger"
        >Marking the user as inactive will prevent the user from logging in to the system.</b-alert
      >
      <b-alert
        v-model="showReassignTerritories"
        variant="warning"
        >Before you can make this user inactive. You need to reassign their sales territories.</b-alert
      >
      <div class="form-row align-items-center">
        <b-colxx
          md="6"
          sm="12">
          <m-text-input
            id="user-first-name"
            label="First Name"
            :rules="{ required: true }"
            :value="editUser.first_name"
            :alternative="false"
            :group="false"
            name="First Name"
            @update-value="
              (input) => {
                editUser.first_name = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="6"
          sm="12">
          <m-text-input
            id="user-last-name"
            label="Last Name"
            :rules="{ required: true }"
            :value="editUser.last_name"
            :alternative="false"
            :group="false"
            name="Last Name"
            @update-value="
              (input) => {
                editUser.last_name = input
              }
            " />
        </b-colxx>

        <b-colxx
          md="6"
          sm="12">
          <m-text-input
            id="user-phone-name"
            label="Phone"
            :rules="{ max: 14 }"
            :value="editUser.phone"
            :alternative="false"
            :group="false"
            :type="'phone'"
            name="Phone"
            @update-value="
              (input) => {
                editUser.phone = input
              }
            " />
        </b-colxx>
        <b-colxx
          md="6"
          sm="12"
          ><b-form-group
            id="user-active"
            label="Active"
            class="field-label-form-group mt-2"
            ><base-checkbox
              :checked="editUser.is_active_yn"
              @update-checkbox="updateUserActiveCheckbox()"></base-checkbox
          ></b-form-group>
        </b-colxx>
      </div>
      <b-form-row>
        <b-colxx
          md="6"
          sm="12">
          <div
            v-for="(role, index) in userRoleList"
            :key="role.user_role_id">
            <base-checkbox
              :item="role"
              :index="index"
              :checked="role.checked"
              @update-checkbox="updateRoleCheckbox(role, index)"
              >{{ role.user_role_description }}</base-checkbox
            >
          </div>
        </b-colxx>

        <b-colxx
          v-if="showReassignTerritories"
          md="6"
          sm="12">
          <b-form-group
            id="select-new-sales-rep"
            label="Select Assign Territories To"
            label-for="select-new-rep"
            class="field-label-form-group">
            <b-form-select
              id="select-new-rep"
              :options="filterAssignToUserList"
              v-model="assignTerritoriesToRep"
              value-field="user_id"
              text-field="full_name"></b-form-select>
          </b-form-group>
        </b-colxx>
        <!-- </div> -->
      </b-form-row>

      <m-tracking-info
        :created-by-name="editUser.created_by_name"
        :created-at="editUser.created_at"
        :modified-by-name="editUser.modified_by_name"
        :modified-at="editUser.modified_at" />

      <template #modal-footer="{}">
        <div v-if="!saveProcessComplete">
          <b-button
            size="md"
            class="mr-2"
            variant="success"
            type="submit"
            :disabled="saveInProgress"
            @click="saveUser()">
            <div v-if="!saveInProgress">Save</div>
            <div v-if="saveInProgress">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              <span class="visually-hidden">Saving...</span>
            </div>
          </b-button>
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            :disabled="saveInProgress"
            @click="closeUserModal()">
            Cancel
          </b-button>
        </div>
        <div v-if="saveProcessComplete">
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            @click="closeUserModal()"
            >Close</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import axios from "axios"

  import BaseCheckbox from "@/components/input/BaseCheckbox.vue"

  const userObj = {
    first_name: "",
    last_name: "",
    email: "",
    azure_client_id: "",
    is_active_yn: true,
    user_roles: [],
    phone: "",
  }

  export default {
    name: "UserHome",
    components: {
      BaseCheckbox,
    },

    data() {
      return {
        userList: [],
        user_list_fields: [
          {
            label: "",
            key: "actions",
          },
          {
            label: "Name",
            key: "full_name",
          },
          {
            label: "Email",
            key: "email",
          },
          {
            label: "Roles",
            key: "roles",
          },
          {
            label: "Phone",
            key: "phone",
          },
          {
            label: "Active",
            key: "is_active_yn",
          },
        ],

        azureUsersModal: false,
        azureUserList: [],
        azure_user_list_fields: [
          {
            label: "",
            key: "actions",
          },
          {
            label: "Name",
            key: "displayName",
          },
          {
            label: "Email",
            key: "mail",
          },
          // {
          //   label: "",
          //   key: "confirm",
          // },
        ],

        userRoleList: [],

        current_page: 1,
        per_page: 10,
        total_rows: null,
        prev_page_link: "",
        next_page_link: "",

        editUserModal: false,
        editUser: {},
        editUserIndex: null,
        showUserSaveAlert: false,
        userSaveAlertMessage: "",
        userSaveAlertVariant: "",

        addUser: {},
        addUserIndex: null,
        confirmAddUserAlert: false,
        userAddSuccess: false,

        saveInProgress: false,
        saveProcessComplete: false,

        showReassignTerritories: false,
        assignTerritoriesToRep: null,
      }
    },

    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters("common", ["currentPage", "showLoader"]),
      ...mapGetters("admin", ["editUserYN"]),

      pageTotal() {
        var page_total = 1
        page_total = Math.ceil(this.total_rows / this.per_page)
        return page_total
      },

      filteredAzureUsers() {
        let filtered_users = this.azureUserList.filter(
          ({ mail: value1 }) => !this.userList.some(({ email: value2 }) => value1 === value2)
        )
        return filtered_users
      },

      filterAssignToUserList() {
        let list = this.userList
        let filtered_list = list.filter((x) => x.user_id !== this.editUser.user_id)
        return filtered_list
      },

      showInactiveWarning() {
        if (this.editUser.is_active_yn === false) {
          return true
        } else {
          return false
        }
      },
    },

    created() {},

    mounted() {
      this.$store.dispatch("common/showLoader", true)
      document.title = "User Management"
      this.getUserList()
      this.getUserRoleList()
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async getUserList() {
        // await this.$store.dispatch("auth/refreshToken", this.user.user_id);
        console.log("Right before call user list")
        await django_session
          .get("erp/user/list/", {
            params: {
              page_size: 0,
              page: this.current_page,
            },
          })
          .then((response) => {
            this.userList = response.data
          })
      },

      async getUserRoleList() {
        await django_session
          .get("erp/user/role/list/", {
            params: {
              page_size: 0,
            },
          })
          .then((response) => {
            this.userRoleList = response.data
            const checkedObj = { checked: false }
            for (var x = 0; x < this.userRoleList.length; x++) {
              Object.assign(this.userRoleList[x], checkedObj)
            }
          })
      },

      formatPhoneNumber() {
        var value = this.editUser.phone
        var formattedInput = value.replace(/\D+/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        this.editUser.phone = formattedInput
      },

      async goUserDetail(item, index) {
        // Make sure to name the checkbox
        // const checkboxes = document.querySelectorAll('input[type="checkbox"]')
        // for (var i = 0; i < checkboxes.length; i++) {
        //   checkboxes[i].checked = false
        // }
        this.$store.dispatch("admin/setEditUserYN", true)
        this.resetCheckboxes()
        await django_session
          .get("erp/user/" + item.user_id + "/")
          .then((response) => {
            this.editUser = response.data
            this.editUserIndex = index
          })
          .then(() => {
            for (var i = 0; i < this.userRoleList.length; i++) {
              let has_role = this.editUser.user_roles.find((role) => role.role === this.userRoleList[i].user_role_id)
              if (has_role) {
                this.userRoleList[i].checked = true
                console.log(this.userRoleList[i].checked)
              } else {
                this.userRoleList[i].checked = false
                console.log(this.userRoleList[i].checked)
              }
            }
            this.editUserModal = true
          })
      },

      resetCheckboxes() {
        // this.editRoleYN = false;
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        return new Promise((resolve, reject) => {
          console.log(checkboxes.length)
          for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
            console.log(checkboxes[i].checked);
          }
          resolve();
        });
      },

      updateRoleCheckbox(item, index) {
        console.log(item, index)
        if (this.editUserYN) {
          let findRoleIndex = this.editUser.user_roles.findIndex((role) => role.role === item.user_role_id)
          console.log(findRoleIndex)
          if (findRoleIndex !== -1) {
            console.log("Remove")
            this.editUser.user_roles.splice(findRoleIndex, 1)
            this.userList[this.editUserIndex].user_roles.splice(findRoleIndex, 1)
          }
          if (findRoleIndex === -1) {
            console.log("Add")
            this.editUser.user_roles.push(item)
            this.userList[this.editUserIndex].user_roles.push(item)
          }
        }
        if (!this.editUserYN) {
          let findRoleIndex = this.editUser.user_roles.findIndex((role) => role.user_role_id === item.user_role_id)
          console.log(findRoleIndex)
          if (findRoleIndex !== -1) {
            console.log("Remove")
            this.editUser.user_roles.splice(findRoleIndex, 1)
          }
          if (findRoleIndex === -1) {
            console.log("Add")
            this.editUser.user_roles.push(item)
          }
        }
        
      },

      updateUserActiveCheckbox() {
        this.editUser.is_active_yn = !this.editUser.is_active_yn
        if (!this.editUser.is_active_yn && this.editUser.territories !== null) {
          console.log("User has territories assigned")
          this.showReassignTerritories = true
        } else {
          this.showReassignTerritories = false
        }
      },

      async saveUser() {
        this.saveInProgress = true
        if (this.editUserYN) {
          await django_session
            .patch("erp/user/" + this.editUser.user_id + "/", {
              refresh_token_only: false,
              update_self: false,
              reassign_territories: this.showReassignTerritories,
              assign_territory_to: this.assignTerritoriesToRep,
              refresh_token_only: false,
              user_id: this.editUser.user_id,
              first_name: this.editUser.first_name,
              last_name: this.editUser.last_name,
              user_roles: this.editUser.user_roles,
              is_active_yn: this.editUser.is_active_yn,
              phone: this.editUser.phone,
            })
            .then((response) => {
              console.log(response.data)
              this.userList[this.editUserIndex].email = response.data.email
              this.userList[this.editUserIndex].first_name = response.data.first_name
              this.userList[this.editUserIndex].full_name = response.data.full_name
              this.userList[this.editUserIndex].is_active_yn = response.data.is_active_yn
              this.userList[this.editUserIndex].last_name = response.data.last_name
              this.userList[this.editUserIndex].phone = response.data.phone
              // this.userList[this.editUserIndex].user_roles = response.data.user_roles;
              this.userSaveAlertMessage = "User profile updated."
              this.userSaveAlertVariant = "success"
              this.showUserSaveAlert = true
              this.saveProcessComplete = true
              this.saveInProgress = false
            })
            .catch(() => {
              this.userSaveAlertMessage = "There was an error during the update."
              this.userSaveAlertVariant = "danger"
              this.showUserSaveAlert = true
              this.saveProcessComplete = true
              this.saveInProgress = false
            })
        }

        if (!this.editUserYN) {
          await django_session
            .post("erp/user/create/", {
              azure_client_id: this.editUser.azure_client_id,
              email: this.editUser.email,
              first_name: this.editUser.first_name,
              last_name: this.editUser.last_name,
              is_active_yn: true,
              phone: this.editUser.phone,
              user_roles: this.editUser.user_roles
            })
            .then((response) => {
              console.log(response)
              this.userSaveAlertMessage = "User profile created."
              this.userSaveAlertVariant = "success"
              this.showUserSaveAlert = true
              this.saveProcessComplete = true
              this.userList.push(response.data)
            })
            .catch((error) => {
              console.log(error)
              this.userSaveAlertMessage = "There was an error during the create."
              this.userSaveAlertVariant = "danger"
              this.showUserSaveAlert = true
              this.saveProcessComplete = true
            })
        }
        this.saveInProgress = false
      },

      closeUserModal() {
        this.editUserModal = false
        this.editUser = {}
        this.editUserIndex = null
        this.saveInProgress = false
        this.saveProcessComplete = false
        this.userSaveAlertMessage = ""
        this.userSaveAlertVariant = ""
        this.showUserSaveAlert = false
        this.showReassignTerritories = false
        this.assignTerritoriesToRep = null
        this.$store.dispatch("admin/setEditUserYN", null)
      },

      async openAzureUsersModal() {
        this.$store.dispatch("common/showLoader", true)
        await django_session.get("erp/user/azure-user-list/", {
        }).then((response) => {
          this.azureUserList = response.data.value
        })
        this.azureUsersModal = true
        this.resetCheckboxes()
        this.$store.dispatch("common/showLoader", false)
      },

      addAzureUser(item, index) {
        console.log(item, index)
        for (var i = 0; i < this.userRoleList.length; i++) {
          this.userRoleList[i].checked = false
        }
        this.$store.dispatch("admin/setEditUserYN", false)
        this.editUser = JSON.parse(JSON.stringify(userObj))
        this.editUser.first_name = item.givenName
        this.editUser.last_name = item.surname
        this.editUser.email = item.mail
        this.editUser.azure_client_id = item.id
        this.editUser.is_active_yn = true
        this.editUser.user_roles = []
        this.editUser.phone = ""
        this.azureUsersModal = false
        this.editUserModal = true
      },

      cancelAddAzureUser() {
        this.confirmAddUserAlert = false
        this.azureUsersModal = false
        this.$store.dispatch("admin/setEditUserYN", null)
      },
    },
  }
</script>

<style></style>
