<template>
  <div class="container-fluid my-4">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header bg-info text-white">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 text-lg font-weight-bold">Sales Call Type List</div>
              <div class="flex">
                <b-button variant="success" @click="createSalesCallType()"
                  ><i class="fas fa-circle-plus mr-2" /> New</b-button
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <b-row class="my-1">
              <b-colxx cols="12"
                ><b-alert v-model="showAlert" :variant="alertVariant" dismissible>{{ alertMessage }}</b-alert></b-colxx
              >
            </b-row>
            <b-table
              id="sales-call-category-table"
              ref="__customerTable"
              responsive
              head-variant="dark"
              :items="categoryList"
              :fields="category_list_fields"
              :current-page="current_page"
              per-page="0"
            >
              <template #cell(sales_call_category)="row">
                <div v-if="edit === row.item.sales_call_category_id" class="span">
                  <b-input-group
                    ><b-form-input id="sales-call-type" v-model.trim="row.item.sales_call_category"></b-form-input>
                    <div class="input-group-append">
                      <i
                        v-if="!hideSaveButton"
                        class="fas fa-floppy-disk text-default icon-pointer ml-3"
                        @click="updateSalesCallName(row.item, row.index)"
                      />
                      <i
                        class="fas fa-circle-xmark text-danger icon-pointer ml-3"
                        @click="cancelType()"
                      /></div
                  ></b-input-group>
                </div>
                <div v-else>
                  {{ row.item.sales_call_category }}
                  <i
                    class="fas fa-pencil text-default icon-pointer ml-3"
                    @click="editSalesCallType(row.item, row.index)"
                  />
                </div>
              </template>
              <template #cell(active_yn)="row">
                <b-form-checkbox
                  :id="row.item.sales_call_category_id + '-category-is-active'"
                  switch
                  v-model="row.item.active_yn"
                  @change="typeChangeActive(row.item)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import django_session from "@/api/django_session";

export default {
  name: "SalesCallTypes",
  components: {},

  data() {
    return {
      categoryList: [],
      category_list_fields: [
        {
          key: "sales_call_category",
          label: "Category Name",
        },
        {
          key: "active_yn",
          label: "Active",
        },
      ],

      newCategory: {
        sales_call_category_id: null,
        sales_call_category: "",
        active_yn: true,
      },

      edit: null,
      editIndex: null,
      editTypeYN: null,
      current_page: 1,

      showAlert: false,
      alertMessage: "",
      alertVariant: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),

    hideSaveButton() {
      if (this.newCategory.sales_call_category_id === this.edit) {
        if (this.newCategory.sales_call_category === "") {
          return true;
        }
      }
      return false;
    },
  },

  created() {},

  mounted() {
    document.title = "Manage Sales Call Types";
    django_session
      .get("erp/customer/sales-call-category/list/", {
        params: {
          page_size: 0
        }
      })
      .then((response) => {
        this.categoryList = response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
  },

  methods: {
    createSalesCallType() {
      let randomId = Math.floor(Math.random() * 90000) + 10000;
      this.newCategory.sales_call_category_id = randomId;
      this.categoryList.unshift(this.newCategory);
      this.editTypeYN = false;
      this.edit = randomId;
    },

    cancelType() {
      this.edit = null;
      if (this.editTypeYN === false) {
        this.categoryList.splice(0, 1);
      }
    },

    typeChangeActive(item) {
      item.active_yn != item.active_yn;
      django_session
        .patch("erp/customer/sales-call-category/" + item.sales_call_category_id + "/", {
          active_yn: item.active_yn,
          modified_by: this.user.user_id,
        })
        .then(() => {
          this.alertMessage = "Sales call type active is " + item.active_yn;
          this.alertVariant = "success";
          this.showAlert = true;
        });
    },

    editSalesCallType(item, index) {
      this.edit = item.sales_call_category_id;
      this.editIndex = index;
      this.editTypeYN = true;
    },

    updateSalesCallName(item, index) {
      console.log(item, index);
      if (this.editTypeYN === true) {
        django_session
          .patch("erp/customer/sales-call-category/" + item.sales_call_category_id + "/", {
            sales_call_category: item.sales_call_category,
            modified_by: this.user.user_id,
          })
          .then((response) => {
            console.log(response.data);
            this.edit = null;
            this.alertMessage = "Sales call type updated.";
            this.alertVariant = "success";
            this.showAlert = true;
          });
      }
      if (this.editTypeYN === false) {
        django_session
          .post("erp/customer/sales-call-category/create/", {
            sales_call_category: this.newCategory.sales_call_category,
            active_yn: this.newCategory.active_yn,
            created_by: this.user.user_id,
            modified_by: this.user.user_id,
          })
          .then((response) => {
            this.categoryList[0].sales_call_category_id = response.data.sales_call_category_id;
            this.edit = null;
            this.alertMessage = "Sales call type created.";
            this.alertVariant = "success";
            this.showAlert = true;
          });
      }
    },
  },
};
</script>

<style scoped></style>
