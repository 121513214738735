<template>
  <div class="container-fluid">
    <b-row class="mt-3">
      <b-colxx cols="12"><h3>Supplier Management</h3></b-colxx></b-row
    >
    <b-row class="my-3">
      <b-colxx md="4" sm="12">
        <m-text-input
          id="supplier-filter"
          :required="false"
          :group="false"
          label="Search"
          :value="filterSupplierName"
          type="text"
          prepend-icon="fas fa-search"
          append-icon="fas fa-circle-xmark text-danger icon-pointer"
          @append-icon-action="() => {
            filterSupplierName = ''
          }"
          @update-value="
            (input) => {
              filterSupplierName = input;
            }
          "
        ></m-text-input>
        <b-form-group
          id="hide-supplier-inactive-fieldset"
          label="Hide Inactive"
          label-for="hide-supplier-inactive-yn"
          class="field-label-form-group"
        >
          <b-form-checkbox id="hide-supplier-inactive-yn" v-model="hideInactiveYN"></b-form-checkbox>
        </b-form-group>
      </b-colxx>
      <b-colxx md="8" sm="12">
        <b-table
          id="product-table"
          ref="__productTable"
          responsive
          head-variant="dark"
          :items="filterSupplierList"
          :fields="supplier_list_fields"
          :current-page="current_page"
          per-page="0"
        >
          <template #cell(actions)="row">
            <i class="fas fa-eye text-default icon-pointer" @click="goSupplierDetail(row.item, row.index)" />
          </template>
          <template #cell(name)="row">
            {{ row.item.name }}
            <span v-if="row.item.is_inactive_yn" class="ml-1" :style="{ display: 'inline-block', color: 'red' }"
              ><i class="fas fa-do-not-enter"></i
            ></span>
          </template>
          <template #cell(modified_at)="row">
            {{ new Date(row.item.modified_at).toLocaleString() }}
          </template>
        </b-table>
        <!-- <b-pagination
          v-model="current_page"
          :total-rows="total_rows"
          :per-page="per_page"
          :number-of-pages="pageTotal"
          size="sm"
          class="my-0"
          aria-controls="product-table"
          @input="getSupplierList(current_page)"
        ></b-pagination> -->
      </b-colxx>
    </b-row>

    <!--  -->
    <!-- Supplier Detail Modal -->
    <!--  -->
    <b-modal
      id="supplier-detail-modal"
      v-model="showSupplierDetailModal"
      :title="supplierDetailModalTitle"
      size="xl"
      centered
      scrollable
      header-bg-variant="default"
      header-text-variant="white"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row class="my-1">
        <b-alert v-model="showAlert" :variant="alertVariant" class="w-100" dismissible>{{ alertMessage }}</b-alert>
      </b-row>
      <div v-if="!createNewNoteYN">
        <b-form-row>
          <b-colxx md="9" sm="12">
            <m-text-input
              id="supplier-name"
              label="Supplier Name"
              :rules="{ required: true, max: 200 }"
              :value="editSupplier.name"
              :alternative="false"
              :group="false"
              name="Supplier Name"
              @update-value="
                (input) => {
                  editSupplier.name = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="3" sm="12"
            ><b-form-group
              id="supplier-inactive-fieldset"
              label="Inactive"
              label-for="supplier-inactive-yn"
              class="field-label-form-group mt-2"
            >
              <b-form-checkbox
                id="supplier-inactive-yn"
                v-model="editSupplier.is_inactive_yn"
              ></b-form-checkbox> </b-form-group
          ></b-colxx>
        </b-form-row>
        <b-row class="my-3">
          <b-colxx cols="12">
            <b-table
              id="supplier-notes-table"
              ref="__supplierNotesTable"
              responsive
              head-variant="dark"
              :items="editSupplier.supplier_notes"
              :fields="supplier_notes_fields"
              per-page="0"
            >
              <template #thead-top>
                <b-tr>
                  <b-th colspan="4"
                    ><b-button size="sm" variant="outline-white" @click="openNewNote()"
                      ><i class="fas fa-square-plus text-primary text-white mr-2" />New Note</b-button
                    ></b-th
                  ></b-tr
                >
              </template>
              <template #cell(created_at)="row">
                {{ new Date(row.item.created_at).toLocaleString() }}
              </template>
            </b-table>
          </b-colxx>
        </b-row>
      </div>

      <div v-if="createNewNoteYN">
        <b-row>
          <b-col
            ><m-text-area
              id="supplier-note"
              :value="note_body"
              name="Notes"
              :rows="7"
              :rules="{ required: true, max: 4000 }"
              labels="Notes"
              :alternative="false"
              :group="false"
              @update-value="(input) => (note_body = input)"
            />
          </b-col>
        </b-row>
      </div>

      <template #modal-footer="{}">
        <div v-if="!createNewNoteYN">
          <b-button
            v-if="!showAlert"
            size="md"
            class="mr-2"
            variant="success"
            :disabled="supplierIsSaving"
            @click="saveSupplier()"
          >
            {{ saveButtonLabel }}
          </b-button>
          <b-button size="md" class="mr-2" variant="info" :disabled="supplierIsSaving" @click="cancelSupplierModal()">
            {{ cancelButtonLabel }}
          </b-button>
        </div>
        <div v-if="createNewNoteYN">
          <b-button size="md" class="mr-2" variant="success" :disabled="disableSaveNoteButton" @click="saveNewNote()">
            Save
          </b-button>
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            @click="
              () => {
                (note_body = ''), (createNewNoteYN = false);
              }
            "
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import django_session from "@/api/django_session";

// import McpInput from "@/components/Inputs/McpInput.vue";

export default {
  name: "SupplierHome",
  components: {
    // McpInput,
  },

  data() {
    return {
      supplierList: [],
      supplier_list_fields: [
        {
          label: "",
          key: "actions",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Last Updated",
          key: "modified_at",
        },
      ],
      supplier_notes_fields: [
        {
          label: "Author",
          key: "created_by_name",
        },
        {
          label: "Content",
          key: "note",
        },
        {
          label: "Date",
          key: "created_at",
        },
      ],
      current_page: 1,
      per_page: 0,
      total_rows: null,
      prev_page_link: "",
      next_page_link: "",

      filterSupplierName: "",
      hideInactiveYN: true,

      showSupplierDetailModal: false,
      editSupplier: {},
      editSupplierIndex: null,

      supplierIsSaving: false,

      createNewNoteYN: false,
      note_body: "",

      showAlert: false,
      alertMessage: "",
      alertVariant: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["currentPage"]),

    pageTotal() {
      var page_total = 1;
      page_total = Math.ceil(this.total_rows / this.per_page);
      return page_total;
    },

    supplierDetailModalTitle() {
      if (this.showSupplierDetailModal === true) {
        return this.editSupplier.name + " Details";
      } else {
        return "";
      }
    },

    saveButtonLabel() {
      if (this.supplierIsSaving === true) {
        return "Saving...";
      } else {
        return "Save";
      }
    },

    cancelButtonLabel() {
      if (this.showAlert === true) {
        return "Close";
      } else {
        return "Cancel";
      }
    },

    disableSaveNoteButton() {
      if (this.note_body === "") {
        return true;
      } else {
        return false;
      }
    },

    filterSupplierList() {
      let list = [];
      if (this.hideInactiveYN === true) {
        list = this.supplierList.filter((obj) => obj.is_inactive_yn === false);
      }
      if (this.hideInactiveYN === false) {
        list = this.supplierList;
      }
      if (this.filterSupplierName !== "") {
        let filteredList = list.filter((obj) => obj.name.toLowerCase().includes(this.filterSupplierName.toLowerCase()));
        return filteredList;
      } else {
        return list;
      }
    },
  },

  created() {},

  mounted() {
    document.title = "Supplier Master List";
    this.getSupplierList();
  },

  methods: {
    async getSupplierList() {
      await django_session
        .get("erp/product/supplier/list/", {
          params: {
            page_size: 0,
            // page: this.current_page,
          },
        })
        .then((response) => {
          // this.total_rows = response.data.count;
          // this.prev_page_link = response.data.previous;
          // this.next_page_link = response.data.next;
          this.supplierList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async goSupplierDetail(item, index) {
      await django_session.get("erp/product/supplier/" + item.erp_supplier_id + "/").then((response) => {
        this.editSupplier = response.data;
      });
      this.editSupplierIndex = index;

      this.showSupplierDetailModal = true;
    },

    cancelSupplierModal() {
      this.showSupplierDetailModal = false;
      this.editSupplier = {};
      this.editSupplierIndex = null;
      this.alertMessage = "";
      this.alertVariant = "";
      this.showAlert = false;
    },

    saveSupplier() {
      django_session
        .patch("erp/product/supplier/" + this.editSupplier.erp_supplier_id + "/", {
          name: this.editSupplier.name,
          address: this.editSupplier.address,
          city: this.editSupplier.city,
          postal_code: this.editSupplier.postal_code,
          phone_1: this.editSupplier.phone_1,
          phone_2: this.editSupplier.phone_2,
          fax: this.editSupplier.fax,
          email: this.editSupplier.email,
          website: this.editSupplier.website,
          include_in_extract_yn: this.editSupplier.include_in_extract_yn,
          include_for_commission_yn: this.editSupplier.include_for_commission_yn,
          is_inactive_yn: this.editSupplier.is_inactive_yn,
          code: this.editSupplier.code,
        })
        .then((response) => {
          console.log(response.data);
          this.supplierList[this.editSupplierIndex].name = response.data.name;
          this.supplierList[this.editSupplierIndex].is_inactive_yn = response.data.is_inactive_yn;
          this.alertMessage = "Supplier updated.";
          this.alertVariant = "success";
          this.showAlert = true;
        })
        .catch((error) => {
          console.log(error);
          this.alertMessage = "Error updating supplier.";
          this.alertVariant = "danger";
          this.showAlert = true;
        });
    },

    openNewNote() {
      console.log("Note");
      this.note_body = "";
      this.createNewNoteYN = true;
    },

    saveNewNote() {
      django_session
        .post("erp/product/supplier-note/create/", {
          supplier: this.editSupplier.erp_supplier_id,
          note: this.note_body,
          created_by: this.user.user_id,
          modified_by: this.user.user_id,
        })
        .then((response) => {
          this.editSupplier.supplier_notes.unshift(response.data);
          this.alertMessage = "Supplier note created.";
          this.alertVariant = "success";
          this.showAlert = true;
          this.createNewNoteYN = false;
        });
    },
  },
};
</script>

<style></style>
